import Vue from 'vue';
import { Iterable } from '../interfaces';

export const filterMixin = Vue.extend({
  data() {
    return {
      filter: {} as Iterable,
      searchTerms: [this.$t('username')],
    };
  },
  methods: {
    newFilter() {
      this.$emit('filter', this.filter);
    },
  },
  computed: {
    searchText(): string {
      return `${this.$t('filter-in')} ${this.searchTerms.join(', ')}.`;
    },
  },
});
