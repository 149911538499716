import { Client } from '@/api/Agency/agency.interface'
import { User } from '@sentry/browser'
import { AxiosResponse } from 'axios'
import { BasicTransaction, HistoryItem } from './topup.interface'
import { PaymentMethods } from '../interfaces'

export type RemittanceStatus =
  | 'Draft'
  | 'Requested'
  | 'Assigned'
  | 'Distributing'
  | 'Delivered'
  | 'Canceled'

export interface CreateTariffDTO {
  uuid?: string
  type: 'CLIENT' | 'AGENCY'
  agency: string
  name: string
  description: string
  min_amount: number
  max_amount: number
  delivery_currency: 'USD' | 'CUP' | 'EUR'
  exchange_rate: string
  to_agencies?: string[]
  regions: number[]
  min_commission: string
  percent_commission: string
  is_active: boolean
}

export interface Tariff extends CreateTariffDTO {
  uuid: string
  region: number
  region_name: string
  name: string
  description: string
  min_amount: number
  max_amount: number
  created_at: string
  updated_at: string
  type: 'CLIENT' | 'AGENCY'
  delivery_currency: 'USD' | 'CUP' | 'EUR'
  exchange_rate: string
  min_commission: string
  percent_commission: string
  to_agency: string
  to_agency_name: string
  is_active: boolean
  per_region: {
    uuid: string
    subregion: number
    subregion_name: string
    region: string
    min_commission: string
    percent_commission: string
    is_active: boolean
    created_at: string
    updated_at: string
  }[]
}

export interface SubRegionTariff {
  subregion: number
  subregion_name: string
  min_commission: number
  percent_commission: number
  is_active: boolean
}

export interface PriceConsult {
  client_origin: string
  client_destiny: string
  amount: number
  client_price: number
  price_to_pay: number
  cost: number
  excharge_rate: number
  was_sent: boolean
}

export interface PriceConsultDTO {
  client_origin: string
  client_destiny: string
  amount: string | number
  delivery_currency: string
  tariff: string
  distribute_with_network: boolean
}

export interface Remittance {
  uuid: string
  code: string
  remittance: string
  client_origin: string
  client_origin_obj: Client
  client_origin_name: string
  client_destiny: string
  client_destiny_obj: Client
  client_destiny_name: string
  amount: number
  exchange_rate: number
  delivery_amount: number
  delivery_currency: DeliveryCurrenciesEnum
  assigned_to: string
  assigned_to_name: string
  status: string
  description: string
  price: number
  full_price: string
  cost: number
  full_cost: string
  earning: string
  from_agent_name: string
  from_agent: string
  agent_name: string
  agent: string
  from_agency_name: string
  from_agency: string
  agency_name: string
  agency: string
  created_at: string
  updated_at: string
  created_by: string
  request_signature: boolean
  signature_image: string
  region: number
  subregion: number
  subregion_name: string
  region_name: string
  delivery_agency: string
  history: HistoryItem[]
  agency_assignment_rejected: boolean
  messages: RemittanceMessage[]
  payment_method: PaymentMethods
}

export interface RemittancePrice {
  agency: string
  min_amount: number
  max_amount: number
  percent: number
}

export interface CreateWholesalerTariffDTO {
  uuid?: string
  region: number
  name: string
  description: string
  min_amount: number
  max_amount: number
  min_commission: number
  percent_commission: number
  is_active: boolean
}

export interface WholesalerTariff extends CreateWholesalerTariffDTO {
  uuid: string
  region_name: string
  created_at: string
  updated_at: string
}

export interface Wholesaler extends User {
  tariffs: WholesalerTariff[]
}

export interface RemittanceRange {
  region: number
  min_amount: number
  max_amount: number
}

export interface WholesalerTransaction {
  uuid: string
  created_by: string
  agency: string
  agency_name: string
  with_user: string
  with_user_name: string
  with_agency: string
  with_agency_name: string
  amount: number
  description: string
  created_at: string
  updated_at: string
}

export interface RemittanceTotals {
  earning: string
  total: number
  total_amount: string
  total_assigned: number
  total_canceled: number
  total_cost: string
  total_delivered: number
  total_distributing: number
  total_price: string
  total_requested: number
  [key: string]: string | number
}

export interface CreateRemittanceMessageDTO {
  message: string
  remittance: string
}

export interface RemittanceMessage {
  agency: string
  attached_by: string
  date: string
  message: string
  status: string
  promise?: Promise<AxiosResponse>
}

export type DeliveryCurrencies = 'USD' | 'CUP' | 'EUR' | 'GPB'

export interface RemittanceTransaction extends BasicTransaction {
  type: RemittanceTransactionType
}

export interface RemittanceAgencyTransaction extends BasicTransaction {
  type: RemittanceAgencyTransactionType
}

export enum RemittanceTransactionType {
  REMITTANCE_CREATE = 'REMITTANCE_CREATE',
  REMITTANCE_REFUND = 'REMITTANCE_REFUND',
}

export enum RemittanceAgencyTransactionType {
  REMITTANCE_CREATE = 'REMITTANCE_CREATE',
  REMITTANCE_REFUND = 'REMITTANCE_REFUND',
}

export interface UpdateMultipleTariff {
  description: string
  min_amount: number
  max_amount: number
  tariffs: string[]
  exchange_rate: string
  min_commission: string
  percent_commission: string
  is_active: boolean
}

export enum DeliveryCurrenciesEnum {
  CUP = 'CUP',
  USD = 'USD',
  EUR = 'EUR',
  MLC = 'MLC',
  MLX = 'MLX',
}

export type DeliveryCurrency = 'CUP' | 'USD' | 'EUR' | 'MLC' | 'MLX'

export interface CreateAutoassignDTO {
  regions: number[]
  messenger: string
  is_active: boolean
  range: number
}

export interface Autossign {
  uuid: string
  rate: number
  regions: number[]
  regions_data: RegionData[]
  messenger: string
  messenger_name: string
  is_active: boolean
  created_at: string
  updated_at: string
}

export interface CreateAutoUpDTO {
  regions: number[]
  agency: string
  is_active: boolean
  delivery_currency: DeliveryCurrenciesEnum
}

export interface RegionData {
  name: string
  id: number
}

export interface AutoUp {
  uuid: string
  regions: number
  regions_data: RegionData[]
  agency: string
  agency_name: string
  is_active: boolean
  delivery_currency: DeliveryCurrenciesEnum
  created_at: string
  updated_at: string
}

export interface CreateRemittanceBalance {
  agency: string
  delivery_currency: DeliveryCurrenciesEnum
  remittance_without_balance: boolean
}

export interface RemittanceBalance {
  uuid: string
  balance: string
  agency: string
  agency_name: string
  delivery_currency: DeliveryCurrenciesEnum
  remittance_without_balance: boolean
  created_at: string
  updated_at: string
}

export interface RemittanceBalanceOperation {
  agency: string
  amount: string
  description: string
  delivery_currency: DeliveryCurrenciesEnum
}

export interface RemittanceBalanceTransaction extends BasicTransaction {}

export enum RemittanceBalanceTransactionEnum {
  REMITTANCE_CREATE = 'REMITTANCE_CREATE',
  REMITTANCE_REFUND = 'REMITTANCE_REFUND',
  BALANCE_PAYMENT = 'BALANCE_PAYMENT',
  BALANCE_CHARGE = 'BALANCE_CHARGE',
}

export enum ClientRelation {
  PARENT = 'PARENT',
  BROTHER = 'BROTHER',
  SPOUSE = 'SPOUSE',
  SON = 'SON',
  UNCLE = 'UNCLE',
  GRANDPARENT = 'GRANDPARENT',
  COUSIN = 'COUSIN',
  FRIEND = 'FRIEND',
  KNOWN = 'KNOWN',
}

export interface RemittanceFee {
  agency: string
  agency_name: string
  created_at: string
  delivery_currency: DeliveryCurrenciesEnum
  fee: string
  is_active: boolean
  updated_at: string
  uuid: string
}

export interface CreateRemittanceFee {
  agency: string
  is_active: boolean
  fee: string
  delivery_currency: DeliveryCurrenciesEnum
}

export interface CopyRemittanceBody {
  remittance: string
  tariff: string
  amount: string
  request_signature: boolean
  description: string
}
