











































































import { paginatorMixin } from '@/utils/mixins/paginatorMixin'

export default paginatorMixin().extend()
