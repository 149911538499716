


































import Vue from 'vue'
import TextButton from './TextButton.vue'
export default Vue.extend({
  components: {
    TextButton,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      hasBeenOpened: false,
    }
  },
  watch: {
    value(value: boolean) {
      if (value && !this.hasBeenOpened) this.hasBeenOpened = true
    },
  },
  methods: {
    onFilter() {
      this.$emit('filter')
    },
    onInput(event: boolean) {
      if (!this.hasBeenOpened && event) this.hasBeenOpened = event
      this.$emit('input', event)
    },
  },
})
