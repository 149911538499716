import { render, staticRenderFns } from "./Table.vue?vue&type=template&id=f515f602&scoped=true&"
import script from "./Table.vue?vue&type=script&lang=ts&"
export * from "./Table.vue?vue&type=script&lang=ts&"
import style0 from "./Table.vue?vue&type=style&index=0&id=f515f602&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f515f602",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDataTable,VDivider,VPagination,VSkeletonLoader,VTextField})
