var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('FlatCard',{attrs:{"refresh":_vm.onRefresh},scopedSlots:_vm._u([{key:"titleText",fn:function(){return [_vm._t("title",[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.name))+" ")])]},proxy:true},{key:"filter",fn:function(){return [_c('CreditTransactionsFilter',{attrs:{"isBill":_vm.isBill,"types":_vm.types},on:{"filter":_vm.newFilter}})]},proxy:true}],null,true)},[_c('v-card-text',[_c('FlatTable',{ref:"table",attrs:{"itemsPerPage":50,"getter":_vm.getter,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.amount))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.type))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.asDateTime(item.created_at))+" ")]}},{key:"item.details",fn:function(ref){
var item = ref.item;
return [_c('TransactionsDetailsButton',{attrs:{"item":item}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }