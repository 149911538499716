







































import ActionButton from '@/components/ActionButton.vue'
import Card from '@/components/Card.vue'
import Table from '@/components/Table.vue'
import { tableComposition } from '@/compositions/table'
import { ApiController } from '@/utils/classes/ApiController'
import { defineComponent, PropType } from '@vue/composition-api'
import TransactionsDetailsButton from './Agency/Transactions/TransactionsDetailsButton.vue'
import FlatCard from './FlatCard.vue'
import FlatTable from './FlatTable.vue'
import CreditTransactionsFilter from './Services/TopUps/Transactions/CreditTransactionsFilter.vue'

export default defineComponent({
  components: {
    Card,
    ActionButton,
    AppTable: Table,
    CreditTransactionsFilter,
    TransactionsDetailsButton,
    FlatCard,
    FlatTable,
  },
  props: {
    controller: {
      type: Object as PropType<ApiController<any, any>>,
      required: true,
    },
    types: Array,
    isBill: Boolean,
  },
  setup: props => {
    return {
      ...tableComposition(props.controller),
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('created_by'),
          value: 'created_by',
        },
        {
          text: this.$t('origin'),
          value: 'origin',
          notShow: this.isBill,
        },
        {
          text: this.$t('destiny'),
          value: 'destiny',
          notShow: this.isBill,
        },
        {
          text: this.$t('with_agency'),
          value: 'with_agency_name',
          notShow: !this.isBill,
        },
        {
          text: this.$t('type'),
          value: 'type',
        },
        {
          text: this.$t('currency'),
          value: 'currency',
          notShow: this.isBill,
        },
        {
          text: this.$t('amount'),
          value: 'amount',
        },
        {
          text: this.$t('created_at'),
          value: 'created_at',
        },
        {
          text: this.$t('details'),
          value: 'details',
        },
      ],
    }
  },
})
