






































import Vue from 'vue'
import moment from 'moment'

export default Vue.extend({
  props: {
    value: String,
    min: String,
    max: String,
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      menu: false,
    }
  },
  methods: {
    formatDate(date: string): string {
      if (date) {
        moment.locale(this.$store.getters.lang)
        return moment(date).format('ddd, MMM DD YYYY')
      }
      return ''
    },
  },
})
