











import Vue from 'vue'

export default Vue.extend({
  props: {
    icon: { type: String, default: 'mdi-plus' },
    responsive: { type: Boolean, default: true },
  },
  mounted() {
    this.$emit('mounted')
  },
})
