import {
  Params,
  Body,
  MessengerConfig,
  Paginator,
  CreateMessengerConfig,
} from '@/utils/interfaces'
import { axiosApi } from '@/axios'
import Axios, { AxiosResponse } from 'axios'
import { User } from '@/api/Auth/auth.interface'
import { createOrUpdateJSON } from '@/utils/functions'
import {
  ConsultPriceBody,
  ConsultPriceResponse,
  CreateMessengerDTO,
} from './remittances.dto'
import {
  CreateRemittanceMessageDTO,
  CreateTariffDTO,
  CreateWholesalerTariffDTO,
  PriceConsult,
  PriceConsultDTO,
  Remittance,
  RemittancePrice,
  RemittanceRange,
  RemittanceTotals,
  RemittanceTransaction,
  RemittanceAgencyTransaction,
  Tariff,
  Wholesaler,
  WholesalerTariff,
  WholesalerTransaction,
  CreateAutoassignDTO,
  Autossign,
  CreateAutoUpDTO,
  AutoUp,
  RemittanceBalance,
  RemittanceBalanceOperation,
  RemittanceBalanceTransaction,
  RemittanceFee,
  CreateRemittanceFee,
  CopyRemittanceBody,
} from '@/utils/interfaces/remittance.interface'
import { ApiController } from '@/utils/classes/ApiController'

export const getMessengers = (params?: Params): Promise<AxiosResponse<User>> =>
  axiosApi.get('/remittance/messengers/', { params })

export const createMessenger = (
  body: CreateMessengerDTO,
): Promise<AxiosResponse<MessengerConfig>> =>
  createOrUpdateJSON('/remittance/messenger-config/', body)

export const toggleMessenger = (body: { uuid: string; is_active: boolean }) =>
  axiosApi.patch(`/remittance/messenger-config/${body.uuid}/`, {
    is_active: body.is_active,
  })

export const createTariff = (
  body: CreateTariffDTO,
): Promise<AxiosResponse<Tariff>> => {
  return createOrUpdateJSON('/remittance/tariff/', body)
}

export const createMultipleTariff = (
  body: CreateTariffDTO,
): Promise<AxiosResponse<Tariff>> => {
  return axiosApi.post<Tariff>('/remittance/multiple-tariff/', body)
}

export const getTariffs = (
  params?: Params,
): Promise<AxiosResponse<Paginator<Tariff>>> =>
  axiosApi.get('/remittance/tariff/', { params })

export const deleteTariff = (uuid: string) =>
  axiosApi.delete(`/remittance/tariff/${uuid}/`)

export const consultPrice = (
  body: PriceConsultDTO,
): Promise<AxiosResponse<PriceConsult>> =>
  axiosApi.post('/remittance/consult/', body)

export const createRemittance = (
  body: Body,
): Promise<AxiosResponse<Remittance>> =>
  axiosApi.post('/remittance/list/', body)

export const createRemittancePrice = (
  body: Body,
): Promise<AxiosResponse<RemittancePrice>> =>
  createOrUpdateJSON('/remittance/prices/', body)

export const getRemittancePrices = (
  params?: Params,
): Promise<AxiosResponse<Paginator<RemittancePrice>>> =>
  axiosApi.get('/remittance/prices/', { params })

export const getRemittances = (
  params?: Params,
): Promise<AxiosResponse<Paginator<Remittance>>> =>
  axiosApi.get('/remittance/list/', { params })

export const getRemittanceById = (
  uuid: string,
): Promise<AxiosResponse<Remittance>> =>
  axiosApi.get(`/remittance/list/${uuid}/`)

export const getWholesalers = (
  params?: Params,
): Promise<AxiosResponse<Paginator<Wholesaler>>> =>
  axiosApi.get('/remittance/wholesalers/', { params })

export const createOrUpdateWholesalerTariff = (
  body: CreateWholesalerTariffDTO,
): Promise<AxiosResponse<WholesalerTariff>> =>
  createOrUpdateJSON('/remittance/wholesaler-tariff/', body)

export const cancelRemittance = (
  remittance: string,
): Promise<AxiosResponse<any>> =>
  axiosApi.post('/remittance/cancel/', { remittance })

export const rejectRemittance = (
  remittance: string,
  description: string,
): Promise<AxiosResponse<any>> =>
  axiosApi.post('/remittance/agency-reject/', { remittance, description })

export const assignRemittance = (
  remittance: string,
  assigned_to: string,
  description: string,
  custom_commission?: number,
): Promise<AxiosResponse<any>> =>
  axiosApi.post('/remittance/assign/', {
    remittance,
    assigned_to,
    custom_commission,
    description,
  })

export const reassignRemittance = (
  remittance: string,
  assigned_to: string,
  description: string,
  custom_commission?: number,
): Promise<AxiosResponse<any>> =>
  axiosApi.post('/remittance/reassign/', {
    remittance,
    assigned_to,
    custom_commission,
    description,
  })

export const assignUp = (remittance: string): Promise<AxiosResponse<any>> =>
  axiosApi.post('/remittance/assign/', {
    remittance,
    distribute_with_network: true,
  })

export const getRemittancePDF = (
  remittance: string,
): Promise<AxiosResponse<any>> => axiosApi.get(`/remittance/pdf/${remittance}/`)

export const getRemittanceInvoicePDF = (
  params?: Params,
): Promise<AxiosResponse<any>> =>
  axiosApi.get('/remittance/invoice/', { params })

export const deliverRemittance = (
  remittance: string,
  delivery_file: null | Blob,
  id: string,
): Promise<AxiosResponse<any>> => {
  const formdata = new FormData()
  formdata.append('remittance', remittance)
  if (delivery_file) {
    formdata.append('delivery_file', delivery_file, 'id_file.jpeg')
  }
  formdata.append('delivery_identity', id)
  return axiosApi.post('/remittance/delivery/', formdata, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const confirmRemittance = (
  remittance: string,
): Promise<AxiosResponse<any>> =>
  axiosApi.post('/remittance/confirm/', { remittance })

export const requestSignature = (
  remittance: string,
): Promise<AxiosResponse<any>> =>
  axiosApi.post('/remittance/request-signature/', { remittance })

export const postSignature = (body: {
  signature: string
  image: string
}): Promise<AxiosResponse<any>> => {
  const formdata = new FormData()
  formdata.append('signature', body.signature)
  formdata.append('image', body.image)

  return Axios.post('/api/remittance/verify-signature/', formdata, {
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getRange = (
  region: number,
): Promise<AxiosResponse<Paginator<RemittanceRange>>> =>
  axiosApi.get('/remittance/range-tariff/', {
    params: {
      region,
    },
  })

export const getWholesalerTransactions = (
  params?: Params,
): Promise<AxiosResponse<Paginator<WholesalerTransaction>>> =>
  axiosApi.get('/billtransactions/', { params })

export const activeTariff = (body: {
  is_active: boolean
  uuid: string
}): Promise<AxiosResponse<Tariff>> =>
  axiosApi.patch(`/remittance/tariff/${body.uuid}/`, body)

export const generateRemittanceReport = (
  params?: Params,
): Promise<AxiosResponse<any>> =>
  axiosApi.get('/remittance/xls-export/', {
    params,
    responseType: 'arraybuffer',
  })

export const generateRemittanceAccountingReport = (
  params?: Params,
): Promise<AxiosResponse<any>> =>
  axiosApi.get('/remittance/xls-accounting/', {
    params,
    responseType: 'arraybuffer',
  })

export const remittanceTotals = (
  params?: Params,
): Promise<AxiosResponse<RemittanceTotals>> =>
  axiosApi.get('/remittance/totals/', { params })

export const createRemittanceMessage = (body: CreateRemittanceMessageDTO) =>
  axiosApi.post<CreateRemittanceMessageDTO>('/remittance/attach-message/', body)

export const remittanceTransactionsController = new ApiController<
  RemittanceTransaction,
  any
>('/remittance/transactions/')

export const remittanceAgencyTransactionsController = new ApiController<
  RemittanceAgencyTransaction,
  any
>('/remittance/billtransactions/')

export const updateMultipleTariff = (body: any) =>
  axiosApi.post('/remittance/update-multiple-tariff/', body)

export const remittanceInvoiceDetails = (uuid: string) =>
  axiosApi.get(`/remittance/invoice-detail/${uuid}/`, {
    responseType: 'arraybuffer',
  })

export const autoAssignController = new ApiController<
  Autossign,
  CreateAutoassignDTO
>('/remittance/auto-assign/')

export const fixRemittance = (
  uuid: string,
  body: { client_origin?: string; client_destiny?: string },
) => axiosApi.patch(`/remittance/fix/${uuid}/`, body)

export const autoUpController = new ApiController<AutoUp, CreateAutoUpDTO>(
  '/remittance/auto-up/',
)

export const remittanceBalanceController = new ApiController<
  RemittanceBalance,
  any
>('/remittance/balances/list/')

export const remittanceBalanceSelfController = new ApiController<
  RemittanceBalance,
  any
>('/remittance/balances/self/')

export const createRemittanceBalancePayment = (
  body: RemittanceBalanceOperation,
) => axiosApi.post('/remittance/balances/payment/', body)

export const createRemittanceBalanceCharge = (
  body: RemittanceBalanceOperation,
) => axiosApi.post('/remittance/balances/charge/', body)

export const remittanceBalanceTransactionController = new ApiController<
  RemittanceBalanceTransaction
>('/remittance/balances/transactions/')

export const remittanceBalanceSelfTransactionController = new ApiController<
  RemittanceBalanceTransaction
>('/remittance/balances/self-transactions/')

export const remittanceFeeController = new ApiController<
  RemittanceFee,
  CreateRemittanceFee
>('/remittance/fee/')

export const deleteMultipleTariffs = (tariffs: string[]) =>
  axiosApi.post('/remittance/delete-multiple-tariff/', { tariffs })

export const getRemittanceReports = async (params?: Params) => {
  const response = await axiosApi.get('/remittance/report/', { params })
  response.data = {
    count: response.data.length,
    results: response.data,
    next: null,
    previous: null,
  }
  return response
}

export const processMLCRemittance = (uuid: string) =>
  axiosApi.patch(`/remittance/process-mlc-remittance/${uuid}/`)

export const copyRemittance = (body: CopyRemittanceBody) =>
  axiosApi.post<CopyRemittanceBody>('/remittance/copy/', body)

export const messengerConfigController = new ApiController<
  MessengerConfig,
  CreateMessengerConfig
>('/remittance/messenger-config/')

export const consultRemittancePrice = (body: ConsultPriceBody) =>
  axiosApi.post<ConsultPriceResponse>('/remittance/consult/', body)
