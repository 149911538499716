
































import Vue from 'vue'
import Dialog from '@/components/Dialog.vue'
import ActionButton from '@/components/ActionButton.vue'
export default Vue.extend({
  components: {
    Dialog,
    ActionButton,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      dialog: false,
    }
  },
})
