






















import Vue from 'vue';
export default Vue.extend({
  props: {
    fab: Boolean,
  },
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark;
    },
  },
});
