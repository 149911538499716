




















import Vue, { PropType } from 'vue'
import { getterFunction } from '@/utils/interfaces'
import { debounce } from 'debounce'

export default Vue.extend({
  props: {
    getter: {
      required: true,
      type: Function as PropType<getterFunction>,
    },
    value: [String, Number, Array, Object],
    searchInput: {
      required: false,
      default: '',
      type: String,
    },
    itemText: { type: String, default: 'name' },
    itemValue: { type: String, default: 'uuid' },
    returnObjectAndValue: Boolean,
    limit: {
      type: Number,
      default: 10,
    },
    autoSelectFirst: Boolean,
  },
  data() {
    return {
      items: [] as unknown[],
      stopNext: false,
      search: this.searchInput,
      loading: false,
    }
  },
  computed: {
    returnObject() {
      return this.$attrs['return-object'] || this.$attrs['return-object'] === ''
    },
  },
  methods: {
    refresh() {
      this.newItems()
    },
    async newItems() {
      try {
        this.loading = true
        const response = await this.getter({
          search: this.search || undefined,
          limit: this.limit,
        })
        this.items = response.data.results
        if (this.autoSelectFirst && !!this.items[0]) {
          if (this.$attrs.returnObject || this.returnObjectAndValue) {
            // @ts-ignore
            this.onInput(this.items[0])
          } else {
            // @ts-ignore
            this.onInput(this.items[0][this.itemValue])
          }
        }
      } finally {
        this.loading = false
      }
    },
    onInput(e: Event & { [key: string]: string }) {
      this.stopNext = true
      if (this.returnObjectAndValue) {
        this.$emit('input', e[this.itemValue])
        this.$emit('selected-object', e)
      } else {
        this.$emit('input', e)
      }
    },
    filter(): void {
      if (this.stopNext) {
        this.stopNext = false
        return
      }
      if (this.search) {
        this.loading = true
        const timeout = setTimeout(() => {
          this.loading = false
        }, 2500)
        this.filterDebounce(this, this.search, timeout)
      }
    },
    stopNextFilter(): void {
      this.stopNext = true
    },
    filterDebounce: debounce(
      async (vm: any, search: string, timeout: NodeJS.Timeout) => {
        const response = await vm.getter({
          search: search || undefined,
          limit: vm.limit,
        })
        clearTimeout(timeout)
        vm.loading = false
        vm.items = response.data.results
      },
      200,
    ),
  },
  watch: {
    search() {
      try {
        this.filter()
      } catch (error) {
        console.log(error)
      }
    },
    searchInput(value: string) {
      this.search = value
      this.filter()
    },
  },
  mounted() {
    this.newItems()
  },
})
