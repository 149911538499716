





































import Vue from 'vue'
import moment from 'moment'
import DatePicker from './DatePicker.vue'

export default Vue.extend({
  components: {
    appDatePicker: DatePicker,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    dense: Boolean,
    hideDetails: Boolean,
  },
  data() {
    return {
      dateOptions: [
        'today',
        'this-week',
        'last-week',
        'this-month',
        'personalized',
      ],
      gte: new Date().toISOString().substring(0, 10),
      lte: new Date().toISOString().substring(0, 10),
      selectedDateOption: '',
    }
  },
  methods: {
    allowedLower(v: string | undefined) {
      const today = new Date().toISOString().substring(0, 10)
      if (v) {
        if (this.gte) {
          return v >= this.gte && v <= today
        } else return v <= today
      } else return true
    },
    allowedBigger(v: string | undefined) {
      const today = new Date().toISOString().substring(0, 10)
      if (v) {
        if (this.lte) {
          return v <= this.lte && v <= today
        } else return v <= today
      } else return true
    },
    getData() {
      let minDate = ''
      let maxDate = ''
      moment.locale('en')
      switch (this.selectedDateOption) {
        case 'today':
          minDate = moment()
            .startOf('day')
            .toISOString()
          maxDate = moment()
            .endOf('day')
            .toISOString()
          break
        case 'yesterday':
          minDate = moment()
            .startOf('day')
            .add(-1, 'days')
            .toISOString()
          maxDate = moment()
            .endOf('day')
            .add(-1, 'days')
            .toISOString()
          break
        case 'this-week':
          minDate = moment()
            .startOf('week')
            .toISOString()
          maxDate = moment()
            .endOf('week')
            .toISOString()
          break
        case 'last-week':
          minDate = moment()
            .startOf('week')
            .add(-7, 'days')
            .toISOString()
          maxDate = moment()
            .endOf('week')
            .add(-7, 'days')
            .toISOString()
          break
        case 'this-month':
          minDate = moment()
            .startOf('month')
            .toISOString()
          maxDate = moment()
            .endOf('month')
            .toISOString()
          break
        case 'personalized':
          minDate = moment(this.gte)
            .startOf('day')
            .toISOString()
          maxDate = moment(this.lte)
            .endOf('day')
            .toISOString()
          break
        default:
          break
      }
      return {
        minDate,
        maxDate,
      }
    },
  },
})
