var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":"","flat":""}},[_c('v-data-table',_vm._g(_vm._b({attrs:{"items":_vm.computedItems,"options":_vm.options,"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":"","server-items-length":_vm.data.count,"footer-props":{
      'items-per-page-options': [
        _vm.defaultItemsPerPage,
        2 * _vm.defaultItemsPerPage,
        3 * _vm.defaultItemsPerPage ],
      'show-current-page': true,
    }},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody","types":{
          'table-row': ("table-cell@" + (Math.min(_vm.computedHeaders.length, 6))),
          'table-row-divider': 'table-row, divider',
          'table-tbody': ("table-row-divider@" + (10)),
        }}})]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(props){return [_vm._t(name,null,null,props)]}}}),{key:"top",fn:function(){return [_vm._t("top"),(_vm.topPagination)?[_c('v-pagination',{staticClass:"my-1",attrs:{"color":"primary","length":_vm.pageCount,"total-visible":"11"},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}}),_c('v-divider')]:_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('v-divider'),_c('v-pagination',{staticClass:"my-1",attrs:{"color":"primary","length":_vm.pageCount,"total-visible":"11"},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", $$v)},expression:"options.page"}})]},proxy:true},{key:"footer.page-text",fn:function(ref){
        var pageStart = ref.pageStart;
        var pageStop = ref.pageStop;
        var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" "+_vm._s(_vm.$t('of'))+" "+_vm._s(itemsLength)+" "),_c('span',{staticClass:"ml-8"},[_vm._v(" "+_vm._s(_vm.$t('page'))+" ")]),_c('v-text-field',{staticClass:"d-inline-flex centered-input",staticStyle:{"max-width":"80px"},attrs:{"dense":"","type":"number","max":_vm.pageCount,"min":1,"hide-details":""},model:{value:(_vm.options.page),callback:function ($$v) {_vm.$set(_vm.options, "page", _vm._n($$v))},expression:"options.page"}})]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }