

























import Vue from 'vue'
import TextButton from './TextButton.vue'
export default Vue.extend({
  components: { TextButton },
  props: {
    refresh: Function,
  },
})
