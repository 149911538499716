
























import Vue, { PropType } from 'vue';
import { User } from '@/api/Auth/auth.interface';
export default Vue.extend({
  props: {
    item: Object as PropType<User>,
  },
});
