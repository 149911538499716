import { AxiosResponse } from 'axios'
import { debounce } from 'debounce'
import Vue, { PropType } from 'vue'
import {
  getterFunction,
  Paginator,
  Params,
  TableHeader,
  TableOptions,
} from '../interfaces'

export const paginatorMixin = <
  T extends { uuid: string; notShow?: boolean }
>() =>
  Vue.extend({
    props: {
      getter: Function as PropType<getterFunction>,
      itemsPerPage: {
        type: Number,
        default: 5,
        required: false,
      },
      headers: {
        type: Array as PropType<TableHeader[]>,
        required: true,
      },
      topPagination: Boolean,
    },
    data() {
      return {
        data: {
          count: 0,
          results: [] as T[],
        } as Paginator<T>,
        loading: false,
        pendingRequest: null as null | Promise<AxiosResponse<Paginator<T>>>,
        lastParams: {} as Params,
        lastFilter: {} as Params,
        pageCount: 1,
        defaultItemsPerPage: 5,
        options: {
          itemsPerPage: this.itemsPerPage,
          page: 1,
        } as TableOptions,
      }
    },
    computed: {
      computedHeaders() {
        return this.headers.filter(header => !header.notShow)
      },
      computedItems(): T[] {
        return this.data.results.filter(item => !item.notShow)
      },
    },
    watch: {
      options: {
        handler(value: TableOptions) {
          this.loading = true
          this._debounce(value, this)
        },
        deep: true,
      },
    },
    methods: {
      _debounce: debounce(function(value: TableOptions, vm: any) {
        vm.getData({
          ...vm.lastParams,
          limit: value.itemsPerPage,
          offset: value.itemsPerPage * (value.page - 1),
        })
      }, 1000),
      async getData(params: Params): Promise<void> {
        try {
          this.loading = true
          const request = this.getter<T>(params)
          this.pendingRequest = request
          const response = await request
          this.lastParams = response.config.params || {}
          const { limit, offset, ...lastFilter } = {
            ...response.config.params,
          } as { limit: number; offset: number; lastFilter: Params }
          this.lastFilter = lastFilter
          this.data = response.data
        } finally {
          this.pendingRequest = null
          this.loading = false
        }
      },
      async findAll() {
        while (this.data.results.length < this.data.count) {
          const params = {
            offset: this.data.results.length,
          }
          await this.getData(params)
        }
      },
      async newSearch(search: string): Promise<void> {
        const params = {
          search: search,
          limit: this.options.itemsPerPage,
        }
        this.getData(params)
      },
      async newFilter(params: Params): Promise<void> {
        this.getData({ ...params, limit: this.options.itemsPerPage })
      },
      addItem(item: T): void {
        this.data.results.push(item)
        this.data.count++
      },
      editItem(item: T): void {
        const items = this.data.results
        const index = items.findIndex(element => element.uuid === item.uuid)
        this.data.results.splice(index, 1, item)
      },
      removeItem(item: T): void {
        const items = this.data.results
        const index = items.findIndex(element => element.uuid === item.uuid)
        this.data.results.splice(index, 1)
      },
      refresh() {
        this.getData(this.lastParams)
      },
    },
    created() {
      if (this.itemsPerPage) {
        this.defaultItemsPerPage = this.itemsPerPage
      }
    },
  })
