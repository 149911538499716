






















































import Vue from 'vue'
import ActionButton from './ActionButton.vue'
export default Vue.extend({
  components: { ActionButton },
  props: {
    noTitle: Boolean,
    titleClass: {
      type: String,
      default: '',
    },
    titleBackground: {
      type: String,
      default: 'primary',
    },
    titleWidth: String,
    avatarSize: Number,
    refresh: Function,
  },
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark
    },
    hasTabs(): boolean {
      return true
    },
  },
})
