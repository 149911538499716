import { ApiController } from '@/utils/classes/ApiController'
import { Iterable, Params, VueTable } from '@/utils/interfaces'
import { Ref, ref } from '@vue/composition-api'

export const tableComposition = <T>(
  apiController: ApiController<T, any>,
  params?: Params,
) => {
  const dialogModel = ref(false)
  const editedItem = ref(null) as Ref<T | null>
  const table = ref(null) as Ref<null | VueTable>
  const loading = ref(false)

  const tableOn = {
    'update:loading': ($event: boolean) => (loading.value = $event),
  }

  const newFilter = async (payload: Iterable): Promise<void> => {
    await table.value?.newFilter(payload)
  }

  const onCopy = (item: T): void => {
    const newItem: T & { uuid?: string } = JSON.parse(JSON.stringify(item))
    if (newItem.uuid) {
      delete newItem.uuid
    }
    editedItem.value = newItem
    dialogModel.value = true
  }

  const onCreate = (): void => {
    editedItem.value = null
    dialogModel.value = true
  }

  const onEdit = (item: T): void => {
    editedItem.value = item
    dialogModel.value = true
  }

  const onNew = (item: T): void => {
    // @ts-ignore
    if (editedItem.value?.uuid) {
      table.value?.editItem(item)
      editedItem.value = null
    } else table.value?.addItem(item)
  }

  const onRefresh = async () => {
    await table.value?.refresh()
  }

  return {
    dialogModel,
    editedItem,
    getter: (functionParams?: Params) =>
      apiController.getter({ ...functionParams, ...params }),
    newFilter,
    onCopy,
    onCreate,
    onEdit,
    onNew,
    onRefresh,
    table,
    tableOn,
    tableLoading: loading,
    toggle: apiController.toggle,
  }
}
