























import Vue from 'vue'
import FilterDrawer from '@/components/FilterDrawer.vue'
export default Vue.extend({
  components: {
    appFilterDrawer: FilterDrawer,
  },
  data() {
    return {
      filterModel: false,
    }
  },
  computed: {
    isDark(): boolean {
      return this.$vuetify.theme.dark
    },
  },
})
