







import { remittanceAgencyTransactionsController } from '@/api/Remittances/remittances.api'
import TransactionsView from '@/components/TransactionsView.vue'
import { RemittanceAgencyTransactionType } from '@/utils/interfaces/remittance.interface'
import Vue from 'vue'
export default Vue.extend({
  components: {
    TransactionsView,
  },
  data() {
    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      types: this.$utils.makeSelectable(RemittanceAgencyTransactionType),
      controller: remittanceAgencyTransactionsController,
    }
  },
})
