import { createOrUpdate } from '@/utils/functions'
import { Body, Params, Iterable, Paginator } from '@/utils/interfaces'
import axios, { AxiosResponse } from 'axios'
import {
  TreeviewUser,
  User,
  UserPayment,
  UserStats,
} from '@/api/Auth/auth.interface'
import { axiosApi } from '@/axios'
import { RemoveCreditDTO } from '@/utils/interfaces/topup.interface'

const ENDPOINT = '/users/'

export function createUser(body: Body): Promise<AxiosResponse<User>> {
  return createOrUpdate(ENDPOINT, body, 'image')
}

export function getUsers(
  params: Params,
): Promise<AxiosResponse<Paginator<User>>> {
  return axiosApi.get(ENDPOINT, {
    params,
  })
}

export function getUserById(uuid: string): Promise<AxiosResponse<User>> {
  return axiosApi.get(ENDPOINT + uuid + '/')
}

export function toggleActive(body: User) {
  if (body.role === 'ONLINE')
    return axiosApi.patch(`${ENDPOINT}${body.uuid}/`, {
      is_manual_verified: body.is_manual_verified,
    })
  return axiosApi.patch(`${ENDPOINT}${body.uuid}/`, {
    is_active: body.is_active,
  })
}

export const updateUser = (body: Iterable) =>
  axiosApi.patch(`${ENDPOINT}${body.uuid}/`, body)

export const getUserTree = (
  parent?: string,
): Promise<AxiosResponse<Paginator<TreeviewUser>>> =>
  axiosApi.get('/user-tree/', {
    params: { parent__uuid: parent, limit: 200 },
  })

export const getUserStats = (uuid: string): Promise<AxiosResponse<UserStats>> =>
  axiosApi.get(`/user-stats/${uuid}/`)

export const removeNetCredit = (uuid: string) =>
  axiosApi.post<RemoveCreditDTO>('/topups/remove-net-credit/', {
    agent: uuid,
  })

export const userPayment = (
  body: UserPayment,
): Promise<AxiosResponse<UserPayment>> => axiosApi.post('/user-payment/', body)

export const userCharge = (
  body: UserPayment,
): Promise<AxiosResponse<UserPayment>> => axiosApi.post('/user-charge/', body)
