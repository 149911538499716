
















































































import FilterComponent from '@/components/FilterComponent.vue'
import { getAgencies } from '@/api/Agency/agency.api'
import Autocomplete from '@/components/Autocomplete.vue'
import { filterMixin } from '@/utils/mixins/filterMixin'
import { getUsers } from '@/api/Users/users.api'
import UserListItem from '@/components/UserListItem.vue'
import { DatePicker } from '@/utils/interfaces'
import RangeDatePicker from '@/components/RangeDatePicker.vue'
import FlatFilter from '~/components/FlatFilter.vue'

export default filterMixin.extend({
  props: {
    types: Array,
    isBill: Boolean,
  },
  components: {
    FilterComponent,
    Autocomplete,
    UserListItem,
    RangeDatePicker,
    FlatFilter,
  },
  data: function() {
    return {
      getAgencies: getAgencies,
      getUsers: getUsers,
    }
  },
  methods: {
    newFilter() {
      const dates = (this.$refs.datePicker as DatePicker).getData()
      const filter = {
        ...this.filter,
        created_at__gte: dates.minDate,
        created_at__lte: dates.maxDate,
      } as { [key: string]: any }
      this.$emit('filter', filter)
    },
  },
})
